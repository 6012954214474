import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { AddImg } from '../../../components/AddPhotos/AddImg';
import { Loader } from '../../../components/Loaders';
import { Highlight } from '../../../components/Overlay';
import { Buttons } from '../components/Buttons';
import { SuccessEditing } from '../components/SuccessEditing';
import { updatePhotos } from '../services/editProfile';
import styles from './styles.module.css';
import { Tooltip } from './Tooltip';

interface Photo {
  id: string;
  principal: boolean;
  url: string;
}

interface Props {
  overlay: number;
  setOverlay: (overlay: number) => void;
  initialData: any;
  userId: number;
  reload: () => void;
}

export const StepTwo = ({ overlay, setOverlay, initialData, userId, reload }: Props) => {
  const [logo, setLogo] = useState('');
  const [hasLogo, setHasLogo] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [defaultImage, setDefaultImage] = useState('');
  const [resetTrigger, setResetTrigger] = useState(false);

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
  }, [showSuccessMessage]);

  useEffect(() => {
    const { urlCompanyLogo } = initialData;
    if (!!urlCompanyLogo) {
      setHasLogo(true);
      setLogo(urlCompanyLogo);
      treatDefaultImage();
    }
  }, [initialData]);

  const logoOnDrop = (photo: Photo) => {
    setLogo(photo.url);
    setHasLogo(true);
  };

  const logoOnRemove = () => {
    setLogo('');
    setHasLogo(false);
  };

  const logoOnReset = () => {
    const { urlCompanyLogo } = initialData;
    if (!!urlCompanyLogo) {
      setHasLogo(true);
      setLogo(initialData.urlCompanyLogo);
    }
    setResetTrigger(true);
  };

  const treatDefaultImage = () => {
    const { urlCompanyLogo } = initialData;
    if (!!urlCompanyLogo && urlCompanyLogo.indexOf('data:image') === -1) {
      setDefaultImage(`${process.env.FTP_IMAGENS}${initialData.urlCompanyLogo}`);
    } else if (!!urlCompanyLogo) {
      setDefaultImage(urlCompanyLogo);
    }
  };

  const saveChanges = () => {
    setLoading(true);
    initialData.urlCompanyLogo = logo;
    delete initialData.id;
    delete initialData.urlFrontagePhoto;
    updatePhotos(userId, initialData)
      .then((response: any) => {
        if (response.status === 200) {
          setShowSuccessMessage(true);
          reload();
        }
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        setOverlay(0);
      });
  };

  const undoChanges = (e: any) => {
    e.stopPropagation();
    logoOnReset();
    setOverlay(0);
  };

  const cxFirstUpload = cx(
    styles.firstUploadWrapper,
    'w-100 flex justify-center items-center items-start-ns',
  );

  const cxMainClass = cx(
    'relative bg-white mt8-ns flex flex-column items-center flex-row-ns br10-ns',
    {
      'pb16-ns': overlay === 2,
    },
  );

  const cxHighlight = cx({
    pb24: overlay !== 2,
  });

  const logoTitle = () => (
    <h3 className="self-start mt0 mb24 mb32-ns abbey f18 fw7">Foto de perfil</h3>
  );

  const tooltipContent = (
    <>
      <span className="fw7"> Use uma foto de perfil </span>
      <span className="fw3"> para dar mais credibilidade aos seus anúncios </span>
    </>
  );

  const getTooltip = () => {
    return (
      <Tooltip
        content={tooltipContent}
        bgColor="green"
        className="grass-green"
        showTooltip={!hasLogo}
        small
      >
        <div className={cxFirstUpload}>
          <AddImg
            id="logo_company"
            renderTitle={false}
            title={logoTitle}
            sizeLogo="profileLogo"
            description="a foto"
            onDrop={logoOnDrop}
            remove={logoOnRemove}
            hasImage={hasLogo}
            defaultImage={defaultImage}
            resetTrigger={resetTrigger}
            setReseted={setResetTrigger}
            iconOn="top"
          />
        </div>
      </Tooltip>
    );
  };

  const uploadLogo = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <div className={cxMainClass}>{getTooltip()}</div>
        <Buttons
          className="pb8-ns"
          firstClick={saveChanges}
          secondClick={undoChanges}
          shouldRender={overlay === 2 && !showSuccessMessage}
        />
        <SuccessEditing isForm={false} shouldRender={showSuccessMessage} />
      </>
    );
  };

  return (
    <>
      <Highlight
        setOverlay={setOverlay}
        overlay={overlay}
        stepId={2}
        title="Foto de perfil"
        className={cxHighlight}
      >
        {uploadLogo()}
      </Highlight>
    </>
  );
};
