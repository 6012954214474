import React, { Component } from 'react';
import cx from 'classnames';
import { inject, observer } from 'mobx-react';
import { Container } from '../../components/Container';
import { Grid } from '../../components/Grid';
import { GridCell } from '../../components/GridCell';
import { Icon } from '../../components/Icons';
import { Loader } from '../../components/Loaders';
import { Overlay } from '../../components/Overlay';
import { AsideMyAds } from '../AdList/components';
import { ProfileType } from '../AdList/utils/types';
import { UserData } from '../Login/components/SignIn/store/user';
import { TypeClientStore } from '../SubscriptionPlans/store/selectedStore';
import { ServiceData } from './interfaces/userServices';
import { getUserServices, getUserDataById } from './services/editProfile';
import { StepFive } from './StepFive';
import { StepFour } from './StepFour';
import { StepNine } from './StepNine';
import { StepOne } from './StepOne';
import { StepSeven } from './StepSeven';
import { StepSix } from './StepSix';
import { StepThree } from './StepThree';
import { StepTwo } from './StepTwo';
import styles from './style.module.css';

interface State {
  overlay: number;
  loading: boolean;
  userData: UserData | null;
  userServicesData: ServiceData;
}

interface Props {
  user?: any;
  selectedStore?: TypeClientStore;
}

@inject('user')
@inject('selectedStore')
@observer
export class EditProfile extends Component<Props, State> {
  state = {
    overlay: 0,
    loading: true,
    userData: null,
    userServicesData: {
      id: 0,
      name: '',
      description: '',
      type: 1,
      services: [],
      status: 1,
      businessHours: [],
      highlight: false,
    },
  };

  setOverlay = (overlay: number) => {
    this.setState({
      overlay,
    });
  };

  componentDidMount() {
    this.reloadUserData();
  }

  getUserServices = async (userId: number) => {
    const defaultUserServicesResponse: ServiceData = {
      id: 0,
      name: '',
      description: '',
      type: 1,
      services: [],
      status: 1,
      businessHours: [],
      highlight: false,
    };
    const {
      id,
      name,
      description,
      price,
      type,
      partType,
      services,
      status,
      businessHours,
      highlight,
    } = (await getUserServices(userId)) || {};

    return Object.assign(defaultUserServicesResponse, {
      id,
      name,
      description,
      price,
      type,
      partType,
      services,
      status,
      businessHours,
      highlight,
    });
  };

  reloadUserData = async () => {
    const { user, selectedStore } = this.props;
    const userId =
      user.profile.admin && selectedStore ? selectedStore.clientStore.id : user.profile.id;

    const [userData, userServicesData] = await Promise.all([
      getUserDataById(userId),
      this.getUserServices(userId),
    ]);

    const { type } = userData;
    userData.type = Number(type);

    if (user.profile.admin && selectedStore) {
      selectedStore.setClientStore(userData);
    } else {
      user.updateUserData(userData);
    }
    this.setState({
      userData,
      loading: false,
      userServicesData,
    });
  };

  getStep(index: number) {
    const propsToPass = {
      overlay: this.state.overlay,
      setOverlay: this.setOverlay,
      initialData: this.state.userData,
    };
    const propsToPassService = {
      overlay: this.state.overlay,
      setOverlay: this.setOverlay,
      initialData: this.state.userServicesData,
    };
    const { user, selectedStore } = this.props;
    const userToEdit =
      user.profile.admin && selectedStore ? selectedStore.clientStore : user.profile;
    const userId = userToEdit ? userToEdit.id : 0;
    const isCompany = user.profile.type === ProfileType.COMPANY;
    if (userToEdit && (!userToEdit.urlCompanyLogo || user.profile.admin) && index === 2) {
      return <StepTwo {...propsToPass} userId={userId} reload={this.reloadUserData} />;
    }
    if (userToEdit && (!userToEdit.urlFrontagePhoto || user.profile.admin) && index === 3) {
      return <StepThree {...propsToPass} userId={userId} reload={this.reloadUserData} />;
    }
    if (userToEdit && user.profile.admin && index === 4) {
      return <StepFour {...propsToPass} userId={userId} reload={this.reloadUserData} />;
    }
    if (userToEdit && index === 5) {
      return <StepFive {...propsToPassService} userId={userId} reload={this.reloadUserData} />;
    }
    if ((isCompany || user.profile.admin) && userToEdit && index === 6) {
      return (
        <StepSix
          {...propsToPassService}
          userId={userId}
          reload={this.reloadUserData}
          isAdmin={user.profile.admin}
        />
      );
    }
    if (userToEdit && index === 7) {
      return <StepSeven {...propsToPassService} userId={userId} reload={this.reloadUserData} />;
    }
    if (userToEdit && index === 9) {
      return (
        <StepNine {...propsToPassService} userId={userId} cleanUser={this.props.user.cleanUser} />
      );
    }
  }

  render() {
    const propsToPass = {
      overlay: this.state.overlay,
      setOverlay: this.setOverlay,
      initialData: this.state.userData,
    };

    const { user, selectedStore } = this.props;
    const userToEdit =
      user.profile.admin && selectedStore ? selectedStore.clientStore : user.profile;

    const fantasyName = userToEdit ? userToEdit.fantasyName : '';
    const name = userToEdit ? userToEdit.name : '';
    const type = userToEdit ? userToEdit.type : '';
    const userId = userToEdit ? userToEdit.id : 0;
    const frontagePhoto = userToEdit ? userToEdit.urlFrontagePhoto : '';
    const logoPhoto = userToEdit ? userToEdit.urlCompanyLogo : '';

    const editProfileContent = () => {
      const { loading } = this.state;
      if (loading) {
        return <Loader />;
      }
      return (
        <div>
          <Overlay {...propsToPass} />
          {this.getStep(5)}
          {this.getStep(6)}
          {this.getStep(7)}
          <StepOne
            {...propsToPass}
            userId={userId}
            type={Number(type) === 2 ? 'PJ' : 'PF'}
            reload={this.reloadUserData}
          />
          {this.getStep(2)}
          {this.getStep(3)}
          {this.getStep(4)}
          {this.getStep(9)}
        </div>
      );
    };

    const cxGrid = cx(
      styles.customPaddingTop,
      'flex justify-center justify-start-m justify-start-l mb24 pt32-m',
    );

    return (
      <>
        <Container>
          <Grid className={cxGrid}>
            <GridCell
              width={[12 / 12, 10 / 12, 3 / 12, 3 / 12]}
              className="noPrint pb32-ns pb0-m pr24-m pr24-l flex"
            >
              <div className="flex flex-auto mb32-m">
                <AsideMyAds
                  name={fantasyName || name}
                  urlFrontagePhoto={frontagePhoto}
                  urlCompanyLogo={logoPhoto}
                  hideMenuOnTablet
                  activeIndex={1}
                  isAdmin={user.profile.admin}
                />
              </div>
            </GridCell>
            <GridCell width={[12 / 12, 10 / 12, 8 / 12, 8 / 12]}>
              <div className={styles.negativeMargin}>
                <section className="flex-ns justify-between noPrint ph16 ph0-ns">
                  <header className="nowrap items-center flex mb24 mb48-m mb48-l">
                    <Icon name="SvgIconEdit" className="mr8 red" />
                    <span className="fw7 f20 f30-m f30-l abbey mt8 mt0-ns">EDITAR PERFIL</span>
                  </header>
                </section>
                {editProfileContent()}
              </div>
            </GridCell>
          </Grid>
        </Container>
      </>
    );
  }
}
